
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { i18n } from '@/translations';
import { PURGE_FILTER_VALUES } from '@/store/mutations.type';
import { FETCH_STATISTICS, FETCH_COORDINATES } from '@/store/actions.type';

@Component({
  computed: mapGetters(['summary', 'filterFormData', 'filterValues', 'filterRefs', 'filterRequest']),
})

export default class FilterTags extends Vue {
  private filterValues!: any;
  private filterRefs!: any;
  private filterRequest!: any;
  private filterFormData!: any;
  private filterRefsSubs: any = {
    countryVal: 'country',
    geoVal: 'federal_district',
    periodVal: 'period_ranges',
    cargoVal: 'cargo_category',
    priceVal: 'price_range',
    categoryVal: 'incident_category',
  };
  private filterTagsDefaults: any = {
    countryVal: i18n.t('strings.allCountries'),
    geoVal: i18n.t('strings.filterFormGeoAll'),
    periodVal: i18n.t('strings.allTime'),
    cargoVal: i18n.t('strings.allCargoCategories'),
    priceVal: i18n.t('strings.allDamage'),
    categoryVal: i18n.t('strings.allIncidentCategories'),
  };

  @Prop({ default: 'dashboard' })
  private location!: string;

  private closeTag(filterName: string, filterValue: number, isDefault: boolean) {
    if (!isDefault) {
      this.dropFilter(filterName, filterValue);
    }
  }

  private async dropFilter(filterName: string, filterValue: number) {
    this.$store.commit(PURGE_FILTER_VALUES, {filterName, filterValue});
    if (this.$route.path.startsWith('/analysis')) {
      await this.$store.dispatch(FETCH_STATISTICS);
    }
    await this.$store.dispatch(FETCH_COORDINATES);
  }

  get tagsParams() {
    const params: any = {};
    if (this.location === 'dashboard') {
      params.showSummary = true;
      params.showTitle = false;
      params.outerWrapperClass = 'page--dashboard__tags-wrap';
      params.innerWrapperClass = 'page--dashboard__tags';
    } else {
      params.showSummary = false;
      params.showTitle = true;
      params.outerWrapperClass = 'c--filter__tags';
      params.innerWrapperClass = 'c--filter__tags-tags';
    }
    return params;
  }

  get filterRefsByValue() {
    const refs: any = {};
    Object.keys(this.filterRefs).map((key: any) => {
      refs[key] = {};
      this.filterRefs[key].map( (item: any) => {
        refs[key][item.value] = {...item};
      });
    });
    return refs;
  }

  get filterTags() {
    const rows: any = [];
    const baseKeys: any = ['searchVal', 'periodVal', 'priceVal'];
    Object.keys(this.filterValues).map((key: any) => {
      if (this.filterValues[key] && baseKeys.includes(key)) {
        const filterTag: any = {};
        filterTag.filterKey = key;
        if (key === 'searchVal') {
          filterTag.tagName = this.filterValues[key];
        } else {
          const sub: string = this.filterRefsSubs[key];
          const value: number = parseInt(this.filterValues[key], 10);
          const ref: string = this.filterRefsByValue[sub][value][`name_${i18n.locale}`];
          filterTag.tagName = ref;
        }
        filterTag.filterValue = '';
        filterTag.isDefault = false;
        rows.push(filterTag);
      } else if (this.filterValues[key] &&
                 this.filterValues[key].length > 0 &&
                 this.filterValues[key][0] !== -1 ) {
        this.filterValues[key].map( (rowValue: any) => {
          const filterTag: any = {};
          filterTag.filterKey = key;
          const sub: string = this.filterRefsSubs[key];
          const value: number = parseInt(rowValue, 10);
          if (value > -1) {
            const ref: string = this.filterRefsByValue[sub][value][`name_${i18n.locale}`];
            filterTag.tagName = ref;
            filterTag.isDefault = false;
            filterTag.filterValue = rowValue;
            rows.push(filterTag);
          }
        });
      } else if (key in this.filterTagsDefaults) {
        rows.push({filterKey: key,
                   tagName: this.filterTagsDefaults[key],
                   filterValue: '',
                   isDefault: true});
      }
    });
    return rows;
  }
}
