
import { Component, Prop, Vue } from 'vue-property-decorator';
import { convertDate, formatFederalDistrict, propI18n } from '@/common/helpers';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['fieldRefs']),
})
export default class MapTooltip extends Vue {
  @Prop({ default: null })
  private data!: any;

  private fieldRefs!: any;

  public openDetailedInfo() {
    this.$parent!.$emit('open-detailed-info', this.data.id);
  }

  public getElem() {
    return this.$refs.container;
  }

  get info() {
    if (!this.data) {
      return null;
    }
    const item = this.data;
    return {
      date: `${convertDate(item.incident_date)} ${item.incident_time ? item.incident_time : ''}`,
      onlyDate: item.incident_date ?
        `${convertDate(item.incident_date)}`
        : this.$t('strings.undefinedAttackMethod'),
      attackMethod: item.attack_method ?
        propI18n(this.fieldRefs.attackMethod[item.attack_method], this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      cargoCategory: item.cargo_category_id ?
        propI18n(this.fieldRefs.cargoCategory[item.cargo_category_id], this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      cargoDescription: item.cargo_description_id ?
        propI18n(this.fieldRefs.cargoDescription[item.cargo_description_id], this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      incidentCategory: item.incident_category_id ?
        propI18n(this.fieldRefs.incidentCategory[item.incident_category_id], this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      informationSource: item.information_source_id ?
        propI18n(this.fieldRefs.informationSource[item.information_source_id], this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      cargoPrice: `${item.cargo_price_rub ? item.cargo_price_rub + '₽' : ''}${item.cargo_price_eur ?
          ` (${item.cargo_price_eur}€)` : ''}`,
      destination: item.destination ? propI18n(item.destination, this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      point_of_departure: item.point_of_departure ? propI18n(item.point_of_departure, this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      type_of_scene: item.type_of_scene ? propI18n(item.type_of_scene, this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      federal_district: item.federal_district
        ? formatFederalDistrict(this.fieldRefs.federalDistrict[item.federal_district], this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
      city: item.city ? propI18n(item.city, this.$i18n)
        : this.$t('strings.undefinedAttackMethod'),
    };
  }
}
