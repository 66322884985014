import { render, staticRenderFns } from "./FilterTags.vue?vue&type=template&id=23fb3020&scoped=true"
import script from "./FilterTags.vue?vue&type=script&lang=ts"
export * from "./FilterTags.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23fb3020",
  null
  
)

export default component.exports