
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { EXPORT_INCIDENTS } from '../store/actions.type';

@Component({
  computed: mapGetters(['currentUser']),
})
export default class ExportButton extends Vue {
  private currentUser!: any;

  private async exportIncidents() {
    if (!this.currentUser.isStaff) {
        return;
    }
    await this.$store.dispatch(EXPORT_INCIDENTS, this.$i18n.locale);
  }
}
