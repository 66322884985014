
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import vuescroll from 'vuescroll';

interface Option {
  text: string | number;
  value: string | number;
}

@Component({
  components: { vuescroll },
})

export default class DropdownMulti extends Vue {
  @Prop({default: ''})
  private placeholder!: string;

  @Prop({default: 198})
  private maxHeightOptions!: number;

  @Prop({ default: [] })
  private options!: Option[];

  @Prop({ default: [] })
  private value!: any;

  @Prop({ default: false })
  private fullBorder!: boolean;

  private currentValues: any = [];

  private selectOpen: boolean = false;

  private scrollOptions = {
    rail: {
      opacity: '0.1',
      background: '#000000',
      size: '4px',
      gutterOfSide: '16px',
      gutterOfEnds: '8px',
    },
    bar: {
      background: '#007AFF',
      keepShow: true,
      specifyBorderRadius: '4px',
      size: '4px',
      minSize: 0.1,
    },
  };

  get dropdownHeightLimit() {
    return this.options.length > 5;
  }

  private closeSelect() {
    this.selectOpen = false;
  }

  @Watch('value')
  private onValueChange() {
    this.currentValues = [...this.value];
  }

  private isChecked(value: number) {
    let result = false;
    if (this.currentValues.includes(value) ||
        (this.currentValues.length === 0 && value === -1)) {
      result = true;
    }
    return result;
  }

  private addValueToCurrentValues(value: number) {
    const defaultValue: number = -1;
    if (!this.currentValues.includes(value)) {
      if (value === defaultValue) {
        this.currentValues = [];
      } else if (this.currentValues.includes(defaultValue)) {
        const elemIndex = this.currentValues.indexOf(defaultValue);
        if (elemIndex > -1) {
          this.currentValues.splice(elemIndex, 1);
        }
      }
      this.currentValues.push(value);
    } else {
      const elemIndex = this.currentValues.indexOf(value);
      if (elemIndex > -1) {
        this.currentValues.splice(elemIndex, 1);
      }
    }
  }

  private selectVal(value: number) {
    this.addValueToCurrentValues(value);
    this.$emit('input', [...this.currentValues]);
  }

  get allowFullBorderClass() {
    let allowed: boolean = false;
    if (this.fullBorder) {
      allowed = true;
    }
    return allowed;
  }

  get formattedOptions() {
    const options: Option[] = this.options.map((opt: Option) => ({ ...opt }));
    return options;
  }
}
