import { Component, Vue } from 'vue-property-decorator';
import { convertDate, formatFederalDistrict, propI18n } from '@/common/helpers';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['coordinates', 'fieldRefs', 'loadingCoordinates', 'currentUser', 'filterRefs']),
})
export default class extends Vue {
  public fieldRefs!: any;

  public filterRefs!: any;

  private coordinates!: any;

  private loadingCoordinates!: any;

  private currentUser!: any;

  get markers() {
    return this.coordinates.filter((item: any) => item.position.lat && item.position.lng)
      .map((item: any) => {
        return {
          ...item,
          position: {
            lat: (+item.position.lat) + (Math.random() - .5) / 1500,
            lng: (+item.position.lng) + (Math.random() - .5) / 1500,
          },
        };
      });
  }
}
