
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import mapStyles from '@/common/mapStyles';
  import GoogleMapsApiLoader from 'google-maps-api-loader';
  import MarkerClusterer from '@google/markerclustererplus';
  import { createMapZoom } from '@/common/helpers';
  import MapTooltip from '@/components/MapTooltip.vue';
  import { MapPopup, classLoader } from '@/common/MapPopup';
  import { SET_MAP_BOUNDS } from '@/store/mutations.type';
  @Component({
    components: { MapTooltip },
  })
  export default class GoogleMap extends Vue {
    public google: any = {};

    public map: any = {};

    public markerCluster: any = null;

    public markerInfo: any = {};

    @Prop({ default: [] })
    public markers!: object[];

    @Prop({ default: false })
    public isLoading!: boolean;

    @Prop({ default: false })
    public initLoad!: boolean;

    @Prop({ default: null })
    public zoom!: any;

    @Prop({ default: null })
    public center!: any;

    @Prop({ default: false })
    public constantZoom!: boolean;

    @Prop({ default: true })
    public allowedToFitBounds!: boolean;

    public mapStyles: any = mapStyles;

    public tooltip: any = null;

    public tooltipContent: any = null;

    public alreadyCentered: boolean = false;

    public async created() {
      const googleMapApi = await GoogleMapsApiLoader({
        apiKey: process.env.VUE_APP_MAP_KEY ? process.env.VUE_APP_MAP_KEY : 'AIzaSyB7G-Lc9mx4bnR3lOCR6dmBEvg_jo3D4KY',
        sensor: false,
      });

      this.google = googleMapApi;
      this.map = new this.google.maps.Map(this.$refs.googleMap,
        {
          center: {
            lat: this.center ? this.center[0] : 49.73,
            lng: this.center ? this.center[1] : 68.76,
          },
          scrollwheel: true,
          zoom: this.zoom ? this.zoom : 3,
          mapTypeControl: true,
          styles: mapStyles,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControlOptions: {
            mapTypeIds: [this.google.maps.MapTypeId.ROADMAP, this.google.maps.MapTypeId.SATELLITE],
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          mapTypeId: this.google.maps.MapTypeId.ROADMAP,
        } as any); // remove warning
      const zoomControl: any = createMapZoom();
      zoomControl.querySelector('.zoom-in-btn').addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() + 1);
      });
      zoomControl.querySelector('.zoom-out-btn').addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() - 1);
      });
      this.google.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.$store.commit(SET_MAP_BOUNDS, this.map.getBounds().toJSON());
      });
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControl);

      // declare class extends google
      classLoader(this.google);
    }


    @Watch('formattedMarkers')
    public createMarkerCluster() {
      if (!this.google) {
        return;
      }
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
      }
      this.markerCluster = new MarkerClusterer(this.map, this.formattedMarkers, {styles: [
      {
        width: 66,
        height: 88,
        url: '/images/m1.svg',
        textColor: '#000000',
        textSize: 13,
        anchorText: [14, 0],
      },
      {
        width: 66,
        height: 88,
        url: '/images/m2.svg',
        textColor: '#000000',
        textSize: 13,
        anchorText: [14, 0],
      },
      {
        width: 73,
        height: 88,
        url: '/images/m3.svg',
        textColor: '#000000',
        textSize: 13,
        anchorText: [14, 0],
      },
      {
        width: 80,
        height: 88,
        url: '/images/m4.svg',
        textColor: '#000000',
        textSize: 13,
        anchorText: [14, 0],
      },
        {
        width: 90,
        height: 88,
        url: '/images/m5.svg',
        textColor: '#000000',
        textSize: 13,
        anchorText: [14, 0],
      },
      ]});
      if ((!this.center || (this.center && this.alreadyCentered)) && this.allowedToFitBounds) {
        this.fitMapBounds();
      }
      if (this.markers.length > 0) {
        this.alreadyCentered = true;
      }
      if (this.constantZoom && this.zoom) {
        this.map.setZoom(this.zoom);
      }
    }

    get formattedMarkers() {
      if (!this.google.maps) {
        return;
      }
      return this.markers.map((m: any) => {
        const tmp = { ...m };
        const marker = new this.google.maps.Marker({
          icon: this.markerIcon,
          position: new this.google.maps.LatLng(m.position.lat, m.position.lng),
        });
        marker.addListener('click', (event: any) => {
          if (this.tooltipContent && this.tooltipContent.id === m.id) {
            this.closeTooltip();
            return;
          }
          const mPosition = marker.getPosition();
          this.tooltipContent = m;
          this.$nextTick(() => {
            this.tooltip = new MapPopup(new this.google.maps.LatLng(mPosition.lat(), mPosition.lng()),
              (this.$refs.tooltip as any).getElem() as HTMLElement);
            this.tooltip.setMap(this.map);
          });
        });
        return marker;
      });
    }

    private closeTooltip() {
      this.tooltip.hide();
      this.tooltipContent = null;
      this.tooltip = null;
    }

    // @Watch('initLoad')
    private fitMapBounds() {
      if (!this.google || !this.markers.length) {
        return;
      }
      const mapBounds = new this.google.maps.LatLngBounds(null);
      this.markers.map((item: any) => {
        mapBounds.extend(item.position);
      });
      this.map.fitBounds(mapBounds);
      /* tslint:disable-next-line */
      this.map.panToBounds(mapBounds);
    }

    get markerIcon() {
      return require('@/assets/icons/marker.svg');
    }
  }
