
import moment from 'moment';
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import Spinner from '@/components/Spinner.vue';
import Dropdown from '@/components/Dropdown.vue';
import DropdownMulti from '@/components/DropdownMulti.vue';
import FilterTags from '@/components/Analysis/FilterTags.vue';
import {i18n} from '@/translations';
import { SET_FILTER_VALUES, PURGE_FILTER_VALUES, SET_FILTER_FIELD } from '@/store/mutations.type';
import {
  FETCH_REFERENCES, FETCH_STATISTICS,
  FETCH_COORDINATES,
} from '@/store/actions.type';
import FormInput from '@/components/Verification/FormInput.vue';
import {convertDate} from '@/common/helpers';

@Component({
  components: {FormInput, Dropdown, DropdownMulti, FilterTags, Spinner},
  computed: mapGetters([
    'filterRefs', 'filterFormData', 'summary', 'filterRequest',
  ]),
})
/*
 география инцидентов sByRegion
 период инцидентов - missing
 категория грузов sByCargoCategory
 размер ущерба sByPrice
 категория инцидентов - sByIncidentCategory
*/

export default class FilterForm extends Vue {

  private tagsLocation: string = 'filterForm';

  private filterRefs!: any;
  private filterFormData!: any;
  private filterRequest!: any;
  private loadingCoordinates!: boolean;
  private isLoading: boolean = false;


  public async mounted() {
    await this.$store.dispatch(FETCH_REFERENCES);
  }

  private async purgeFilterValues() {
    if (!this.isLoading) {
      this.$store.commit(PURGE_FILTER_VALUES, '');
      this.isLoading = true;
      if (this.$route.path.startsWith('/analysis')) {
        await this.$store.dispatch(FETCH_STATISTICS, this.filterRequest);
      }
      await this.$store.dispatch(FETCH_COORDINATES, this.filterRequest);
      this.isLoading = false;
      this.hideFilterForm();
    }
  }

  private async setFilterValues() {
    if (!this.isLoading) {
      this.$store.commit(SET_FILTER_VALUES);
      this.isLoading = true;
      await this.$store.dispatch(FETCH_COORDINATES);
      if (this.$route.path.startsWith('/analysis')) {
        await this.$store.dispatch(FETCH_STATISTICS);
      }
      this.isLoading = false;
      this.hideFilterForm();
    }
  }

  get fullBorder() {
    const value: boolean = true;
    return value;
  }

  get filterSelectData() {
    const capitalize = ([first, ...rest]: any) => first.toUpperCase() + rest.join('');
    const res: any = {
      category: {
        refsKey: 'incident_category',
        translateKey: 'strings.allIncidentCategories',
        valueKey: 'categoryVal',
        options: [],
      },
      price: {
        refsKey: 'price_range',
        translateKey: 'strings.allDamage',
        valueKey: 'priceVal',
        options: [],
      },
      cargo: {
        refsKey: 'cargo_category',
        translateKey: 'strings.filterFormCargoAll',
        valueKey: 'cargoVal',
        options: [],
      },
      period: {
        refsKey: 'period_ranges',
        translateKey: 'strings.filterFormPeriodAll',
        valueKey: 'periodVal',
        options: [],
      },
      country: {
        refsKey: 'country',
        translateKey: 'strings.allCountries',
        valueKey: 'countryVal',
        options: [],
      },
      geo: {
        refsKey: 'federal_district',
        translateKey: 'strings.filterFormGeoAll',
        valueKey: 'geoVal',
        options: [],
      },
    };
    Object.keys(res).map((optionKey: any) => {
      if (this.filterFormData[res[optionKey].valueKey]) {
        const defaultRow: any = {
          value: -1,
          text: capitalize(this.$t(res[optionKey].translateKey)),
        };
        res[optionKey].options.push(defaultRow);
      }
      if (res[optionKey].refsKey in this.filterRefs) {
        this.filterRefs[res[optionKey].refsKey].map((row: any) => {
          res[optionKey].options.push({
            value: row.value,
            text: capitalize(row[`name_${i18n.locale}`]),
          });
        });
      }
    });
    return res;
  }

  private hideFilterForm() {
    this.$emit('hide-filter-form', true);
  }

  get currentDateFormatted() {
    const curDate = new Date();
    return convertDate(curDate as any);
  }

  get dateOptions() {
    return [
      {title: `1 ${this.$i18n.t('strings.month')}`, value: 1},
      {title: `3 ${this.$i18n.t('strings.month')}`, value: 3},
      {title: `6 ${this.$i18n.t('strings.month')}`, value: 6},
      {title: this.$i18n.tc('strings.year', 1), value: 12},
      {title: this.$i18n.tc('strings.year', 2), value: 24},
      {title: this.$i18n.tc('strings.year', 3), value: 36},
      {title: this.$i18n.tc('strings.year', 5), value: 60},
    ];
  }

  private setPeriodVal(month: number) {
    this.$store.commit(SET_FILTER_FIELD, {
      date_to: convertDate(new Date()),
      date_from: convertDate(new Date(moment().subtract(month, 'months').format())),
    });
  }
}
